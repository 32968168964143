import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDJYTesPkuDu5xBPF2g7BxfBpBu2IOJeyc",
  authDomain: "coursecraft.ai",
  projectId: "coursecraftai",
  storageBucket: "coursecraftai.appspot.com",
  messagingSenderId: "866643780812",
  appId: "1:866643780812:web:3f6cf14cc924a0cecfce69",
  measurementId: "G-2XNYZE9L4Q",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const functions = getFunctions(app);

export { db, storage, auth, functions, analytics };
