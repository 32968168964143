import { useSelector } from "react-redux";
import { CourseType, defaultCourse } from "../typings/CourseType";
import { authState } from "../redux/authSlice";
import { FieldDefinitionType } from "../typings/FieldDefinitionType";
import { useCourse } from "../hooks/useCourse";
import DocumentForm from "../components/DocumentForm";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Course() {
  const state = useSelector(authState);
  const { courseId } = useParams<{ courseId?: string }>();
  const [course, prompt] = useCourse(courseId, state.selectedModule || "");
  const [fieldDefinitions, setFieldDefinitions] = useState<
    FieldDefinitionType[] | null
  >(null);

  useEffect(() => {
    if (prompt && course) {
      const relatedFieldList = ["targetAudience"];
      setFieldDefinitions([
        { field: "name", name: "Name", type: "text", minRows: 1 },
        {
          field: "summary",
          name: "Summary",
          type: "textarea",
          minRows: 3,
          systemPrompt: prompt,
          userPrompt: `Write a summary for the course sufficient to guide a subsequent OpenAI api completion prompt. Answer in 100 words or less with no heading.`,
          relatedFields: relatedFieldList,
        },
        {
          field: "synopsis",
          name: "Synopsis",
          type: "textarea",
          minRows: 6,
          systemPrompt: prompt,
          userPrompt: `Write a detailed synopsis for the course curriculum. Introduce the course, generate a list of course modules that are included in the course, and then describe what the user gains from the course at the end. Note that all of the modules are academic, with no field work and no field trips. Modules are just a way of organizing the key components of what is to be learned in the course. Answer with no heading.`,
          relatedFields: relatedFieldList,
        },
        {
          field: "logline",
          name: "Logline",
          type: "textarea",
          minRows: 3,
          systemPrompt: prompt,
          userPrompt: `Write a logline for the course. Answer in 30 words or less with no heading.`,
          relatedFields: relatedFieldList,
        },

        {
          field: "targetAudience",
          name: "Target Audience",
          type: "text",
          minRows: 1,
          userPrompt: `Describe the target audience of the course in 20 words or less. Answer with no heading.`,
          relatedFields: relatedFieldList,
        },
        {
          field: "writingStyle",
          name: "Writing Style",
          type: "text",
          minRows: 1,
        },
        { field: "genre", name: "Genre", type: "text", minRows: 1 },
        { field: "subgenre", name: "Subgenre", type: "text", minRows: 1 },
        { field: "notes", name: "Notes", type: "textarea", minRows: 3 },
        { field: "author", name: "Author", type: "text", minRows: 1 },
      ]);
    }
  }, [prompt, course]);

  return (
    <div className="flex flex-col p-5 space-y-3 text-black bg-white">
      {state.uid && fieldDefinitions && courseId && (
        <DocumentForm<CourseType>
          initialValues={defaultCourse}
          fieldDefinitions={fieldDefinitions}
          title={`Course`}
          collectionName={`users/${state.uid}/courses`}
          docId={courseId}
        />
      )}
    </div>
  );
}
