import { functions } from "../firebase.config";
import { httpsCallable } from "firebase/functions";
import { ApiMessageType } from "../typings/ApiMessageType";

// const processChatApi = httpsCallable(functions, "processChat");
const processChatApi = httpsCallable(functions, "apiChat");
const processChat4Api = httpsCallable(functions, "apiChat4");

type ProcessChatType = {
  messages: ApiMessageType[];
};

export const processChat = async (data: ProcessChatType) => {
  try {
    const response: any = await processChatApi(data);
    return response.data.message;
  } catch (error) {
    console.log("ERROR", error);
    return undefined;
  }
};

export const processChat4 = async (data: ProcessChatType) => {
  try {
    const response: any = await processChat4Api(data);
    return response.data.message;
  } catch (error) {
    console.log("ERROR", error);
    return undefined;
  }
};
