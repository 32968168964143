export type LessonType = {
  //Editable Properties
  name?: string; // The name of the lesson (e.g., "Opening Image", "Catalyst").
  summary?: string; // A brief summary of the events in this lesson.
  description?: string; // A detailed description of the lesson aimint at 500 words.
  lecture?: string; // The text of the lecture for this lesson aiming at 1500 words.
  notes?: string; // Any additional notes or reminders about this lesson.

  //Read-Only Properties
  courseId?: string; // The unique identifier of the course the lesson belongs to.
  moduleId?: string; // The unique identifier of the module the lesson belongs to.
  order?: number; // The position of the lesson within the sequence of lessons.
  wordCount?: number; // The suggested or actual word count for this lesson.
  createdSeconds?: number; // The timestamp for when the lesson was created.
  updatedSeconds?: number; // The timestamp for when the lesson was last updated.
  locked?: boolean; // Whether the lesson is locked for editing.
  id?: string; // The unique identifier for the lesson.
};

export const defaultLesson: LessonType = {
  name: "",
  summary: "",
  description: "",
  lecture: "",
  notes: "",
};
