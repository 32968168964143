import { Stripe, loadStripe } from "@stripe/stripe-js";

let stripePromise: Stripe | null;

const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(
      "pk_live_51NORvoJ5x5EHhFgikEXRhFBIeCB00qGaIjNvSEJFc9GF9bsWREH2ldfcUdBtfe8kEJq3LYJz3tG8guISFLfYp3BK00H4flYfDW"
    );
  }
  return stripePromise;
};
export default initializeStripe;
