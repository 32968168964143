import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authState } from "../redux/authSlice";
import logo from "../assets/coursecraft_black_t.png";
import LoginSmall from "./LoginSmall";

export default function Home() {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  const state = useSelector(authState);
  return (
    <div className="flex flex-col p-5 space-y-5 bg-white">
      <img className="object-contain w-full h-48" src={logo} alt="logo" />

      <div className="max-w-lg mx-auto text-center">
        <h1 className="mb-4 text-4xl font-bold">CourseCraft.ai</h1>

        <h2 className="mb-6 text-2xl font-semibold">
          Unlock Your Course Creation Potential
        </h2>

        <div className="px-4">
          <h3 className="mb-3 text-xl font-medium">
            Simplify Your Online Course Creation
          </h3>
          <p className="mb-4">
            Think of CourseCraft.ai as your personal course architect. Our
            AI-driven platform assists you in developing a captivating theme,
            laying out the fundamental pillars, and designing comprehensive
            lesson plans for your online course.
          </p>

          <h3 className="mb-3 text-xl font-medium">
            Transform Your Teaching Method
          </h3>
          <p>
            Delve into a systematic, step-by-step approach to course creation
            that simplifies your preparation process and helps you craft an
            engaging and impactful learning experience for your students.
          </p>
        </div>
      </div>
      <div className="pb-7">
        {!state.uid ? (
          <LoginSmall />
        ) : (
          <button
            className="mx-auto btn btn-primary"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/courses");
            }}
          >
            Create A Course
          </button>
        )}
      </div>
    </div>
  );
}
