export type MeditationType = {
  //Editable Properties
  name?: string; // The name of the meditation.
  summary?: string; // A brief summary of the meditation.
  description?: string; // A detailed description of the meditation.
  notes?: string; // Any additional notes or reminders about this meditation.

  //Read-Only Properties
  courseId?: string; // The unique identifier of the course the meditation belongs to.
  moduleId?: string; // The unique identifier of the module the meditation belongs to.
  order?: number; // The position of the meditation within the sequence of meditation.
  wordCount?: number; // The suggested or actual word count for this meditation.
  createdSeconds?: number; // The timestamp for when the meditation was created.
  updatedSeconds?: number; // The timestamp for when the meditation was last updated.
  locked?: boolean; // Whether the meditation is locked for editing.
  id?: string; // The unique identifier for the meditation.
};

export const defaultMeditation: MeditationType = {
  name: "",
  summary: "",
  description: "",
  notes: "",
};
