import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import { useCourse } from "../hooks/useCourse";
import CollectionList from "../components/CollectionList";
import { ApiMessageType } from "../typings/ApiMessageType";
import { processChat } from "../utils/processChat";
import { ModuleType } from "../typings/ModuleType";

export default function Modules() {
  const state = useSelector(authState);
  const { courseId } = useParams<{ courseId?: string }>();
  const [course] = useCourse(courseId, state.selectedModule || "");

  if (!state.uid) return <div>No user</div>;
  if (!courseId) return <div>No course</div>;
  if (!course) return <div>Loading</div>;

  const generateMultipleItems = async () => {
    const systemPrompt = `Generate a list of modules for the following course:\n Titled ${course.name}\nSynopsis:\n${course.synopsis}.`;

    const userPrompt = `Generate a list of modules including name and a 200 word summary. Return the module list JSON format as follows: [{"name":"module 1 name", "summary":"module 1 summary", "order": 1}, {"name":"module 2 name", "summary":"module 2 summary", "order": 2}, ...].`;

    try {
      let messages: ApiMessageType[] = [
        {
          role: "system",
          content: systemPrompt,
        },
        {
          role: "user",
          content: userPrompt,
        },
      ];

      console.log("MESSAGES", messages);
      const response = await processChat({ messages });
      console.log("RESPONSE", response);
      if (!response) throw new Error("No response");
      let dataObject = JSON.parse(response);
      return dataObject.map((item: ModuleType) => ({ ...item }));
    } catch (error) {
      console.error("Error generating items: ", error);
      return null;
    }
  };

  return (
    <CollectionList
      collectionName={`users/${state.uid}/courses/${courseId}/modules`}
      title="Modules"
      baseUrl={`/courses/${courseId}/modules/`}
      generateMultipleItems={generateMultipleItems}
    />
  );
}
