import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import { useCourse } from "../hooks/useCourse";
import CollectionList from "../components/CollectionList";

export default function Lessons() {
  const state = useSelector(authState);
  const { courseId, moduleId } = useParams<{
    courseId?: string;
    moduleId?: string;
  }>();
  const [course, module] = useCourse(courseId, moduleId);

  if (!state.uid) return <div>No user</div>;
  if (!courseId) return <div>No course</div>;
  if (!course) return <div>Loading</div>;

  return (
    <CollectionList
      collectionName={`users/${state.uid}/courses/${courseId}/modules/${moduleId}/meditations`}
      title={`Meditations for ${state.selectedModuleName}`}
      baseUrl={`/courses/${courseId}/modules/${state.selectedModule}/meditations/`}
      systemPrompt={`Generate a series of meditation summaries for the following course:\n Titled ${course.name}\nSynopsis:\n${course.synopsis}. \n\nThe meditation summaries should be for the ${state.selectedModuleName} module. \n\nEach meditation should reinforce an important aspect of the module.`}
      userPrompt={`Now, generate a sequence of 14 meditation summaries. Each meditation summary should be about 60 words. Return the list in JSON format as follows: [{"name":"meditation 1 name", "summary":"meditation 1 summary", "order": 1}, {"name":"meditation 2 name", "summary":"meditation 2 summary", "order": 2}, ...].`}
    />
  );
}
