import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Account from "./pages/Account";
import FinishLogin from "./pages/FinishLogin";
import Login from "./pages/Login";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import { authState } from "./redux/authSlice";
import Pricing from "./pages/Pricing";
import Home from "./pages/Home";

import Course from "./pages/Course";
import Modules from "./pages/Modules";
import Module from "./pages/Module";
import Lessons from "./pages/Lessons";
import Lesson from "./pages/Lesson";

import { useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "./firebase.config";
import { updateCourse } from "./redux/courseSlice";
import { CourseType } from "./typings/CourseType";
import Courses from "./pages/Courses";
import Meditations from "./pages/Meditations";
import Meditation from "./pages/Meditation";

export default function App() {
  const state = useSelector(authState);
  const dispatch = useDispatch();

  useEffect(() => {
    const getSelectedStory = async () => {
      const unsubscribe = onSnapshot(
        doc(db, `users/${state.uid}/courses`, state.selectedCourse!),
        async (snapshot) => {
          if (snapshot.exists()) {
            dispatch(updateCourse(snapshot.data() as CourseType));
          }
        }
      );
      return () => unsubscribe();
    };
    if (state.uid && state.selectedCourse) {
      getSelectedStory();
    }
  }, [dispatch, state.selectedCourse, state.uid]);

  return (
    <BrowserRouter>
      <div className="flex flex-col w-screen min-h-screen bg-[#e6e6e6]">
        <Header />
        <div className="flex flex-col flex-grow w-full h-full max-w-5xl p-5 mx-auto">
          <Routes>
            <Route
              path="/courses"
              element={state.uid ? <Courses /> : <Navigate to="/login" />}
            />

            <Route
              path="/courses/:courseId"
              element={state.uid ? <Course /> : <Navigate to="/login" />}
            />

            <Route
              path="/courses/:courseId/modules"
              element={state.uid ? <Modules /> : <Navigate to="/login" />}
            />
            <Route
              path="/courses/:courseId/modules/:moduleId"
              element={state.uid ? <Module /> : <Navigate to="/login" />}
            />

            <Route
              path="/courses/:courseId/modules/:moduleId/lessons"
              element={state.uid ? <Lessons /> : <Navigate to="/login" />}
            />
            <Route
              path="/courses/:courseId/modules/:moduleId/lessons/:lessonId"
              element={state.uid ? <Lesson /> : <Navigate to="/login" />}
            />

            <Route
              path="/courses/:courseId/modules/:moduleId/meditations"
              element={state.uid ? <Meditations /> : <Navigate to="/login" />}
            />
            <Route
              path="/courses/:courseId/modules/:moduleId/meditations/:meditationId"
              element={state.uid ? <Meditation /> : <Navigate to="/login" />}
            />

            <Route
              path="/account"
              element={state.uid ? <Account /> : <Navigate to="/login" />}
            />
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/finish-login" element={<FinishLogin />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>

          <Footer />
        </div>
      </div>
      <ToastContainer />
    </BrowserRouter>
  );
}
