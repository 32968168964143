export type CourseType = {
  //Editable Properties
  name?: string; // The title or name of the course.
  author?: string; // The author or authors of the course.
  logline?: string; // A brief summary of the course, often used in marketing.
  synopsis?: string; // A more detailed summary of the course.

  targetAudience?: string; // The intended audience for the course.
  notes?: string; // Any additional notes or reminders about the course.
  summary?: string; // Summary of the course designed for use in an AI prompt.
  writingStyle?: string; // The writing style of the course (e.g., formal, informal, academic).

  //Read-Only Properties
  order?: number; // The position of the course in a series or collection.
  wordCount?: number; // The total number of words in the course.
  createdSeconds?: number; // The timestamp for when the course was created.
  updatedSeconds?: number; // The timestamp for when the course was last updated.
  locked?: boolean; // Whether the course is locked for editing.

  // ID Properties
  id?: string; // The unique identifier for the course.
};

export const defaultCourse: CourseType = {
  name: "", // The title or name of the course.
  summary: "", // Summary of the course designed for use in an AI prompt.
  author: "", // The author or authors of the course.
  logline: "", // A brief summary of the course, often used in marketing.
  synopsis: "", // A more detailed summary of the course.

  targetAudience: "", // The intended audience for the course.
  notes: "", // Any additional notes or reminders about the course.
  writingStyle: "", // The writing style of the course (e.g., formal, informal, academic).

  order: 0, // The position of the course in a series or collection.
  wordCount: 0, // The total number of words in the course.
  createdSeconds: 0, // The timestamp for when the course was created.
  updatedSeconds: 0, // The timestamp for when the course was last updated.
  locked: false, // Whether the course is locked for editing.
  id: "", // The unique identifier for the course.
};
