export type ModuleType = {
  //Editable Properties
  name?: string; // The character's name.
  summary?: string; // A brief overview of the character.
  description?: string; // A brief description of the character.
  notes?: string; // Any additional notes about the character.

  //Read-Only Properties
  order?: number; // The order of the character in the sequence of modules.
  wordCount?: number; // The total number of words in the character's description.
  createdSeconds?: number; // The timestamp for when the character was created.
  updatedSeconds?: number; // The timestamp for when the character was last updated.
  locked?: boolean; // Whether the course is locked for editing.

  //ID Properties
  id?: string; // The unique identifier for the character.
};

export const defaultModule: ModuleType = {
  name: "", // The module name.
  summary: "", // A brief overview of the module.
  description: "", // A brief description of the module.
  notes: "", // Any additional notes about the module.

  order: 0, // The order of the module in the sequence of modules.
  wordCount: 0, // The total number of words in the character's description.
  createdSeconds: 0, // The timestamp for when the character was created.
  updatedSeconds: 0, // The timestamp for when the character was last updated.
  locked: false, // Whether the module is locked for editing.
};
