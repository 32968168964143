import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import CollectionList from "../components/CollectionList";

export default function Courses() {
  const state = useSelector(authState);

  if (!state.uid) return <div>No user</div>;

  return (
    <CollectionList
      collectionName={`users/${state.uid}/courses`}
      title="Courses"
      baseUrl={`/courses/`}
    />
  );
}
