import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { CourseType, defaultCourse } from "../typings/CourseType";

const initialState: CourseType = defaultCourse;

const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    createCourse(state, action) {
      state = action.payload;
    },

    updateCourse(state, action) {
      Object.keys(action.payload).forEach((key) => {
        state[key as keyof CourseType] = action.payload[key];
      });
    },

    deleteCourse(state) {
      state = {};
    },
  },
});

export const { createCourse, updateCourse, deleteCourse } = courseSlice.actions;

export const courseState = (state: RootState) => state.course;

export default courseSlice.reducer;
