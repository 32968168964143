import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import { useParams } from "react-router-dom";
import { FieldDefinitionType } from "../typings/FieldDefinitionType";
import { useCourse } from "../hooks/useCourse";
import DocumentForm from "../components/DocumentForm";
import { useEffect, useState } from "react";
import useCollection from "../hooks/useCollection";
import useDocument from "../hooks/useDocument";
import { MeditationType, defaultMeditation } from "../typings/MeditationType";

export default function Meditation() {
  const { courseId, moduleId, meditationId } = useParams<{
    courseId?: string;
    moduleId?: string;
    meditationId?: string;
  }>();
  const [course, prompt] = useCourse(courseId, moduleId);
  const state = useSelector(authState);

  const [, , , , , , summary] = useCollection<MeditationType>(
    `users/${state.uid}/courses/${courseId}/modules/${moduleId}/meditations`
  );
  const [meditation, , , , ,] = useDocument<MeditationType>(
    `users/${state.uid}/courses/${courseId}/modules/${moduleId}/meditations`,
    meditationId || ""
  );

  console.log(summary);
  const [fieldDefinitions, setFieldDefinitions] = useState<
    FieldDefinitionType[] | null
  >(null);

  useEffect(() => {
    if (prompt && course && summary && meditation && meditation.name) {
      setFieldDefinitions([
        { field: "name", name: "Name", type: "text", minRows: 1 },
        {
          field: "summary",
          name: "Summary",
          type: "textarea",
          minRows: 2,
          systemPrompt: prompt + "\n\nMeditations list:\n" + summary,
          userPrompt: `Write a 100-word summary of the "${meditation.name}" meditation.`,
          relatedFields: ["summary", "description"],
        },
        {
          field: "description",
          name: "Description",
          type: "textarea",
          minRows: 4,
          systemPrompt: prompt + "\n\nMeditations list:\n" + summary,
          userPrompt: `Write a 500 word meditation text for "${meditation.name}" meditation.`,
          relatedFields: ["summary", "description"],
        },

        { field: "notes", name: "Notes", type: "textarea", minRows: 3 },
      ]);
    }
  }, [meditation, prompt, course, summary]);

  return (
    <div className="flex flex-col p-5 space-y-3 text-black bg-white">
      {course && (
        <div className="text-2xl font-semibold">
          Course: {course.name} / {state.selectedModuleName}
        </div>
      )}
      {state.uid &&
        fieldDefinitions &&
        meditationId &&
        courseId &&
        course &&
        meditation && (
          <DocumentForm<MeditationType>
            initialValues={defaultMeditation}
            fieldDefinitions={fieldDefinitions}
            title={`Selected Meditation`}
            collectionName={`users/${state.uid}/courses/${courseId}/modules/${moduleId}/meditations/`}
            docId={meditationId}
          />
        )}
    </div>
  );
}
