import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ModuleType, defaultModule } from "../typings/ModuleType";
import { FieldDefinitionType } from "../typings/FieldDefinitionType";
import { useCourse } from "../hooks/useCourse";
import DocumentForm from "../components/DocumentForm";

export default function Module() {
  const state = useSelector(authState);
  const { courseId, moduleId } = useParams<{
    courseId?: string;
    moduleId?: string;
  }>();
  const [course, prompt, module] = useCourse(courseId, moduleId);

  const [fieldDefinitions, setFieldDefinitions] = useState<
    FieldDefinitionType[] | null
  >(null);

  useEffect(() => {
    if (prompt && course) {
      const allFields = ["name", "summary", "description"];
      setFieldDefinitions([
        { field: "name", name: "Name", type: "text", minRows: 1 },
        {
          field: "summary",
          name: "Summary",
          type: "textarea",
          minRows: 2,
          systemPrompt: prompt,
          userPrompt:
            "Write a summary of the following module in 100 words or less:",
          relatedFields: allFields,
        },
        {
          field: "description",
          name: "Description",
          type: "textarea",
          minRows: 4,
          systemPrompt: prompt,
          userPrompt: "Write a detailed description of the module:",
          relatedFields: allFields,
        },

        {
          field: "goal",
          name: "Goal",
          type: "textarea",
          minRows: 3,
          systemPrompt: prompt,
          userPrompt: `Describe the goal of the following module in one paragraph with no heading:`,
          relatedFields: allFields,
        },

        { field: "notes", name: "Notes", type: "textarea", minRows: 3 },
      ]);
    }
  }, [prompt, course]);

  return (
    <div className="flex flex-col p-5 space-y-3 text-black bg-white">
      {course && (
        <div className="text-2xl font-semibold">
          Course: {course.name} / {state.selectedModuleName}
        </div>
      )}
      {state.uid && moduleId && courseId && fieldDefinitions && (
        <DocumentForm<ModuleType>
          initialValues={defaultModule}
          fieldDefinitions={fieldDefinitions}
          title={`Selected Module`}
          collectionName={`users/${state.uid}/courses/${courseId}/modules/`}
          docId={moduleId}
        />
      )}
    </div>
  );
}
