import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import { useParams } from "react-router-dom";
import { LessonType, defaultLesson } from "../typings/LessonType";
import { FieldDefinitionType } from "../typings/FieldDefinitionType";
import { useCourse } from "../hooks/useCourse";
import DocumentForm from "../components/DocumentForm";
import { useEffect, useState } from "react";
import useCollection from "../hooks/useCollection";
import useDocument from "../hooks/useDocument";

export default function Lesson() {
  const { courseId, moduleId, lessonId } = useParams<{
    courseId?: string;
    moduleId?: string;
    lessonId?: string;
  }>();
  const [course, prompt] = useCourse(courseId, moduleId);
  const state = useSelector(authState);

  const [, , , , , , summary] = useCollection<LessonType>(
    `users/${state.uid}/courses/${courseId}/modules/${moduleId}/lessons`
  );
  const [lesson, , , , ,] = useDocument<LessonType>(
    `users/${state.uid}/courses/${courseId}/modules/${moduleId}/lessons`,
    lessonId || ""
  );

  console.log(summary);
  const [fieldDefinitions, setFieldDefinitions] = useState<
    FieldDefinitionType[] | null
  >(null);

  useEffect(() => {
    if (prompt && course && summary && lesson && lesson.name) {
      setFieldDefinitions([
        {
          field: "name",
          name: "Name",
          type: "text",
          minRows: 1,
          systemPrompt:
            prompt +
            `\n\nLessons list for the ${state.selectedModuleName} module:\n` +
            summary,
          userPrompt: `Suggest a better name for the "${lesson.name}" lesson of the ${state.selectedModuleName} module of the "${course.name}" course. Provide the name only, without any headings.`,
          relatedFields: ["summary", "description"],
        },
        {
          field: "summary",
          name: "Summary",
          type: "textarea",
          minRows: 2,
          systemPrompt:
            prompt +
            `\n\nLessons list for the ${state.selectedModuleName} module:\n` +
            summary,
          userPrompt: `Write a 60-word summary of the "${lesson.name}" lesson of the ${state.selectedModuleName} module.`,
          relatedFields: ["summary", "description"],
        },
        {
          field: "description",
          name: "Detailed Description",
          type: "textarea",
          minRows: 4,
          systemPrompt:
            prompt +
            `\n\nLessons list for the ${state.selectedModuleName} module:\n` +
            summary,
          userPrompt: `Please provide a 500-word detailed overview of the "${lesson.name}" lesson of the ${state.selectedModuleName} module, focusing solely on the content without any titles or headings.`,
          relatedFields: ["summary", "description"],
        },

        {
          field: "lecture",
          name: "Full Lecture",
          type: "textarea",
          minRows: 6,
          systemPrompt: `Here is the content for the "${lesson.name}" lesson of the "${state.selectedModuleName}" module of the "${course.name}" course:\n\n${lesson.description}`,
          userPrompt: `Rewrite this as a comprehensive essay with a word count of approximately 1500 words. Ensure that the essay is well-structured, provides detailed analysis, includes detailed examples, and focuses solely on the content without any titles or headings.`,
          relatedFields: [],
        },

        {
          field: "notes",
          name: "Notes",
          type: "textarea",
          minRows: 4,
          systemPrompt:
            prompt +
            `\n\nLessons list for the ${state.selectedModuleName} module:\n` +
            summary,
          userPrompt: `Now write a ChatGPT prompt that is detailed enough that ChatGPT could know enough about the context to write a 2000 word essay covering the "${lesson.name}" lesson content of the "${state.selectedModuleName}" module of the "${course.name}" course.`,
          relatedFields: ["summary", "description"],
        },
      ]);
    }
  }, [lesson, prompt, course, summary, state.selectedModuleName]);

  return (
    <div className="flex flex-col p-5 space-y-3 text-black bg-white">
      {course && (
        <div className="text-2xl font-semibold">
          Course: {course.name} / {state.selectedModuleName}
        </div>
      )}
      {state.uid &&
        fieldDefinitions &&
        lessonId &&
        courseId &&
        course &&
        lesson && (
          <DocumentForm<LessonType>
            initialValues={defaultLesson}
            fieldDefinitions={fieldDefinitions}
            title={`Lesson`}
            collectionName={`users/${state.uid}/courses/${courseId}/modules/${moduleId}/lessons/`}
            docId={lessonId}
            autopilot={["description", "lecture"]}
          />
        )}
    </div>
  );
}
